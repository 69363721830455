/**
 * Standaard boilerplate scripts
 */

var addListener = (element, type, callback) => {
    if (element.addEventListener) {
        element.addEventListener(type, callback);
    } else if (element.attachEvent) {
        element.attachEvent('on' + type, callback);
    }
};

var pageLoad = () => {
    if (document.querySelectorAll('.showOnLoad').length) {
        for (var i = 0; i < document.querySelectorAll('.showOnLoad').length; i++) {
            document.querySelectorAll('.showOnLoad')[i].classList.remove('showOnLoad');
        }
    }
};
if (window.attachEvent) {
    window.attachEvent('onload', pageLoad);
} else if (window.addEventListener) {
    window.addEventListener('load', pageLoad, false);
} else {
    document.addEventListener('load', pageLoad, false);
}



// menu
var ulHasChildren = document.getElementsByClassName('menu__list');
for (var i = 0; i < ulHasChildren.length; i++) {
    if (ulHasChildren[i].childNodes.length === 2) {
        ulHasChildren[i].classList.add('has-children');
    }
}

var menuChildToggle = document.getElementsByClassName('has-children');
for (let k = 0; k < menuChildToggle.length; k++) {
    menuChildToggle[k].addEventListener('click', function () {
        var menu = menuChildToggle[k].childNodes;
        if (menuChildToggle[k].classList) {
            menuChildToggle[k].classList.toggle('open-parent');
            menu[1].classList.toggle('open');
        }
    })
}

(() => {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var notificationIE = document.getElementsByClassName('notificationIE')[0];
    var exit = document.getElementsByClassName('notificationIE-exit')[0];
    if (isIE === true) {
        var exitIEFunction = () => notificationIE.parentNode.removeChild(notificationIE);
        notificationIE.classList.add('block');
        notificationIE.classList.remove('hidden');
        exit.onclick = () => exitIEFunction();
        var openZoek = () => window.location = document.location.origin + '/zoeken';
        document.getElementsByClassName('searchIcon')[0].onclick = () => openZoek();
    } else {
        notificationIE.parentNode.removeChild(notificationIE);
    }
})();


(() => {
    var faqItem = document.getElementsByClassName('faq__question');
    var faqAnswer = document.getElementsByClassName('faq__answer');
    for (let i = 0; i < faqAnswer.length; i++) {
        faqAnswer[i].style.height = 0;
    }
    for (let k = 0; k < faqItem.length; k++) {
        let faqSibling = faqItem[k].nextElementSibling;
        faqItem[k].addEventListener('click', () => {
            if (faqSibling.offsetHeight !== faqSibling.scrollHeight) {
                faqItem[k].parentElement.classList.add('active');
                faqSibling.style.height = faqSibling.scrollHeight + "px";
            }
            else {
                faqSibling.style.height = 0;
                faqItem[k].parentElement.classList.remove('active');
            }
        });
    }
})();

(() => {
    var showall = document.getElementsByClassName('show-all');
    for (let k = 0; k < showall.length; k++) {
        showall[k].addEventListener('click', () => {
            var parent = showall[k].parentNode;
            if (parent.classList) {
                parent.classList.toggle('active');
            }
        })
    }
})();

(() => {
    var tabLink = document.querySelectorAll("[data-tabLink]");
    var tabContent = document.querySelectorAll("[data-tabContent]");
    if(tabLink.length > 1) {
        for (let k = 0; k < tabLink.length; k++) {
            tabLink[k].addEventListener('click', () => {
                for (var l = 0; l < tabLink.length; l++) {
                    tabLink[l].classList.remove('active');
                }
                tabLink[k].classList.add('active');
                for (let i = 0; i < tabContent.length; i++) {
                    var tabLinkAttr = tabLink[k].getAttribute("data-tabLink");
                    var tabContentAttr = tabContent[i].getAttribute("data-tabContent");
                    tabContent[i].style.display = "none";
                    if (tabContentAttr === tabLinkAttr) {
                        tabContent[i].style.display = "flex";
                    }
                }
            });
        }
        document.querySelector("[data-defaultOpen]").click();
    }
})();

window.onscroll = () => scrollFunction();
function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementsByClassName("scrollTop")[0].style.display = "flex";
    } else {
        document.getElementsByClassName("scrollTop")[0].style.display = "none";
    }
}

(() => {
    var aHref = document.querySelectorAll('a[href^="#"]');
    for (var k = 0; k < aHref.length; k++) {
        aHref[k].addEventListener('click', function(e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
})();

(() => {
    if (document.querySelector('.nav-main  .searchIcon')){
        let searchIcon = document.querySelector('.nav-main  .searchIcon');
        let menu = document.querySelector('.nav-main  .menu');
        var input = document.getElementById('searchInput');

        if(searchIcon !== null) {
            let searchBar = document.getElementsByClassName('ais-InstantSearch');
            searchIcon.addEventListener('click', function(e) {
                e.preventDefault();
                searchBar[0].classList.add('active');
                menu.setAttribute("style", "pointer-events:none");

                //auto focus
                if(input !== null) {
                    input.focus();
                    input.select();
                }
            });
        }


        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function(s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }


// Detect all clicks on the document
        document.addEventListener("click", function(event) {

// If user clicks inside the element, do nothing
            if (event.target.matches("#searchInput")) return;

            if (event.target.matches(".nav-main  .searchIcon")) return;

            let searchBar = document.getElementsByClassName('ais-InstantSearch');

// If user clicks outside the element, hide it!
            menu.setAttribute("style", "pointer-events:auto");
            searchBar[0].classList.remove('active');

        })
    }
})();


(() => {
    if(document.querySelector('#selectieTool')) {
        let selectieTool = document.querySelector('#selectieTool');
        let selectieToolVakgebied = document.querySelector('#selectieTool .selectieTool-vakgebied');
        let selectieToolType = document.querySelector('#selectieTool .selectieTool-type');

        if(selectieTool !== null) {
            selectieTool.addEventListener("submit", function(e) {
                let vakgebied = selectieToolVakgebied.options[selectieToolVakgebied.selectedIndex].value;
                let type = selectieToolType.options[selectieToolType.selectedIndex].value;
                let url = '';
                if(type !== 'maatwerk'){
                    url = '/opleidingen/' + type + '?vakgebied=' + vakgebied;
                } else {
                    url = '/maatwerk'
                }
                selectieTool.action = url;
            }, false);
        }
    }
})();

if (typeof Object.assign != 'function') {
    Object.assign = function(target) {
        'use strict';
        if (target == null) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
            var source = arguments[index];
            if (source != null) {
                for (var key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
        }
        return target;
    };
}

(() => {
    if(document.getElementById('inputFile')) {
        var inputFile = document.getElementById('inputFile');
        inputFile.addEventListener("change", function () {
            if(this.files[0].size > 5000000){
                alert("Bestand is te groot! Maximaal 5 MB");
                this.value = ""
            } else {
                var files = document.getElementById("inputFile").files;
                var showContent = document.getElementById("inputFileContent");
                var inputFileContent = "<ul>";

                for (var i = 0; i < files.length; i++) {
                    inputFileContent += '<li>' + (i + 1) + '. ' + files[i].name + '</li>';
                }
                inputFileContent += '</ul>';
                showContent.innerHTML = inputFileContent;
            }
        });
    }
})();
