import "es6-promise/auto";
import images from './images';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import Swiper from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            'modal': () => import(/* webpackChunkName: "modal" */ '../vue/Modal.vue'),
            'loader': () => import(/* webpackChunkName: "loader" */ '../vue/Loader.vue'),
            'algolia-filter': () => import(/* webpackChunkName: "algolia-filter" */ '../vue/AlgoliaFilter.vue'),
            'search-bar-algolia': () => import(/* webpackChunkName: "search-bar-algolia" */ '../vue/SearchBarAlgolia.vue'),
        },
        data: {
            'mobileClass': false,
            'showModal': false,
            'showModal2': false,
            'showModal10': false,
            'loading': false,
            'checked': false
        },

        methods: {
            openMobileNav: function () {
                this.mobileClass = !this.mobileClass;
            },
            loadingForm: function (price = 0, formName = 'form') {
                if(price != 0){
                    this.fireGoogleEvent(price, formName);
                }
                this.loading = true;
            },
            fireGoogleEvent: function(price, formName) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'generate_lead',
                  'currency': 'EUR',
                  'form_name': formName,
                  'value': price,
                });
            }


        },
        mounted() {
            require('./javascript');
            require('./swipers');
            require('./polyfill');

            if(localStorage.last){
                if( (Date.now() - localStorage.last ) / (1000*60*60) >= 1){ //Date.now() is in milliseconds, so convert it all to days, and if it's more than 1 day, show the div
                    this.showModal10 = true;
                    localStorage.last = Date.now(); //Reset your timer
                }
            }
            else {
                localStorage.last = Date.now();
                this.showModal10 = true;
            }
        }
    });
};
// Execute async function
main().then( (value) => {
});
